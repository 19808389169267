// import { where } from "firebase/firestore";
// import GenericVinculadoService from "../services/GenericVinculado.service";

// export const GetPortal = async (idEntidade) => {
export const GetPortal = async () => {
  // console.log("GetPortal => idEntidade:", idEntidade);
  // if (idEntidade === undefined) return [];
  let location = window.location.href;
  // console.log("GetPortal => location:", location, "idEntidade:", idEntidade);
  let portalsCollection = [
    { id: "2", urlportal: "sie" },
    { id: "3", urlportal: "transparencia" },
    { id: "4", urlportal: "institucional" },
    { id: "5", urlportal: "cbtkdeduca" },
    { id: "6", urlportal: "helpdesk" },
    { id: "1", urlportal: "" },
  ];
  // console.log("GetPortal => portalsCollection:", portalsCollection);
  let portal = portalsCollection.find((portalItem) => location.includes(portalItem.urlportal));
  // console.log("GetPortal => portal:", portal);
  return portal;
  // let portalsCollection = [];
  // let conditions = [];
  // conditions.push(where("metadados.active", "==", true));
  // conditions.push(where("vinculo.idEntidade", "==", idEntidade));
  // console.log("GetPortal => conditions:", conditions);
  // return await GenericVinculadoService.getCollectionByConditions("portais", conditions)
  //   .then(async (retData) => {
  //     console.log("GetPortal => retData:", retData);
  //     retData[0].forEach((item) => {
  //       console.log("GetPortal => item:", item);
  //       portalsCollection.push({ id: item.id, urlportal: item.data().subdomain });
  //     });
  //     console.log("GetPortal => portalsCollection:", portalsCollection);
  //     let portal = portalsCollection.find((portalItem) => location.includes(portalItem.urlportal));
  //     console.log("GetPortal => portal:", portal);
  //     return portal;
  //   }).catch((error) => {
  //     console.error("GetPortal => error:", error);
  //     return { id: "", urlportal: "" };
  //   });
};
