// import PortaisService from "../services/portais.service";
import { GetPortal } from "./urlPortalUtil";
import GenericService from "../services/Generic.service";
import { CBTKD_ID } from "../constantes/EntidadesConstantes";
// import { GetSubDomain } from "./urlSudDomainUtil";

export const getUrlDetails = async () => {
  let currenteDomain = null;
  let currenteDomainId = null;
  let currenteSubDomain = null;
  let arr = null;
  const host = window.location.host;
  arr = host.split(".");
  if (["preview-sg-cbtkd", "sg-cbtkd", "cbtkd", "localhost:3000", "sibrafe"].includes(arr[0])) {
    currenteSubDomain = "";
  } else {
    currenteSubDomain = arr[0];
    arr = arr.slice(1, arr.length);
  }
  currenteDomain = arr.join(".");

  try {
    return await GenericService.getFilteredCollection(
      "entidades",
      "configuracoes.domain",
      "array-contains",
      currenteSubDomain === "" ? currenteDomain : currenteSubDomain + "." + currenteDomain,
      "",
      ""
    ).then(async (retDataDomain) => {
      // console.log("retDataDomain", retDataDomain, "currenteSubDomain", currenteSubDomain, "currenteDomain", currenteDomain, "retDataDomain[0].id:", retDataDomain[0].id);
      currenteDomainId = retDataDomain ? (retDataDomain[0].id ? retDataDomain[0].id : 1) : 1;
      // console.log("currenteDomainId", currenteDomainId);

      if (currenteSubDomain !== "") {
        // console.log("currenteDomainId", currenteDomainId);
        let CBTKDDomain = await getPortalCBTKD(currenteDomainId);
        let currentePortal = await GetPortal(currenteDomainId);
        // console.log("currentePortal", currentePortal);
        return {
          domain: currenteDomain,
          domainId: currenteDomainId,
          subDomain: currenteSubDomain,
          portal: currentePortal.urlportal,
          portalId: currentePortal.id,
          heroBackgroundColor:
            retDataDomain[0].data().configuracoes.heroBackgroundColor === undefined
              ? "#fff"
              : retDataDomain[0].data().configuracoes.heroBackgroundColor,
          name: retDataDomain[0].data().dadosPessoais.name,
          uf: retDataDomain[0].data().dadosEndereco.logradouroUF ? retDataDomain[0].data().dadosEndereco.logradouroUF : "",
          urlOuvidoria: retDataDomain[0].data().configuracoes.urlOuvidoria ? retDataDomain[0].data().configuracoes.urlOuvidoria : "",
          nameEducacional: retDataDomain[0].data().configuracoes.nameEducacional ? retDataDomain[0].data().configuracoes.nameEducacional : CBTKDDomain.nameEducacional,
          urlEducacional: retDataDomain[0].data().configuracoes.urlEducacional ? retDataDomain[0].data().configuracoes.urlEducacional : CBTKDDomain.urlEducacional,
          nameYouTubeChannel: retDataDomain[0].data().configuracoes.nameYouTubeChannel ? retDataDomain[0].data().configuracoes.nameYouTubeChannel : CBTKDDomain.nameYouTubeChannel,
          urlYouTubeChannel: retDataDomain[0].data().configuracoes.urlYouTubeChannel ? retDataDomain[0].data().configuracoes.urlYouTubeChannel : CBTKDDomain.urlYouTubeChannel,
          tipoEntidade: retDataDomain[0].data().dadosPessoais.tipoEntidade,
          sigla: retDataDomain[0].data().dadosPessoais.sigla,
        };
      } else {
        let currentePortal = await GetPortal(currenteDomainId);
        // console.log("currentePortal", currentePortal);
        return {
          domain: currenteDomain,
          domainId: currenteDomainId,
          subDomain: currenteSubDomain,
          portal: currentePortal.urlportal,
          portalId: currentePortal.id,
          heroBackgroundColor:
            retDataDomain[0].data().configuracoes.heroBackgroundColor === undefined
              ? "#fff"
              : retDataDomain[0].data().configuracoes.heroBackgroundColor,
          name: retDataDomain[0].data().dadosPessoais.name,
          uf: retDataDomain[0].data().dadosEndereco.logradouroUF ? retDataDomain[0].data().dadosEndereco.logradouroUF : "",
          urlOuvidoria: retDataDomain[0].data().configuracoes.urlOuvidoria ? retDataDomain[0].data().configuracoes.urlOuvidoria : "",
          nameEducacional: retDataDomain[0].data().configuracoes.nameEducacional ? retDataDomain[0].data().configuracoes.nameEducacional : "CBTKDeduca",
          urlEducacional: retDataDomain[0].data().configuracoes.urlEducacional ? retDataDomain[0].data().configuracoes.urlEducacional : "https://cbtkd.org.br/cbtkdeduca",
          nameYouTubeChannel: retDataDomain[0].data().configuracoes.nameYouTubeChannel ? retDataDomain[0].data().configuracoes.nameYouTubeChannel : "CBTKDtv",
          urlYouTubeChannel: retDataDomain[0].data().configuracoes.urlYouTubeChannel ? retDataDomain[0].data().configuracoes.urlYouTubeChannel : "https://www.youtube.com/channel/UCZj7s0EdxFN4ZAejFdknndQ",
          tipoEntidade: retDataDomain[0].data().dadosPessoais.tipoEntidade,
          sigla: retDataDomain[0].data().dadosPessoais.sigla,
        };
      }
    });
  } catch (err) {
    console.log(err);
    throw err;
  }
};


const getPortalCBTKD = async () => {
  return await GenericService.getOne("entidades", CBTKD_ID).then(async (retDataDomain) => {
    return {
      nameEducacional: retDataDomain.configuracoes.nameEducacional ? retDataDomain.configuracoes.nameEducacional : "CBTKDeduca",
      urlEducacional: retDataDomain.configuracoes.urlEducacional ? retDataDomain.configuracoes.urlEducacional : "https://cbtkd.org.br/cbtkdeduca",
      nameYouTubeChannel: retDataDomain.configuracoes.nameYouTubeChannel ? retDataDomain.configuracoes.nameYouTubeChannel : "CBTKDtv",
      urlYouTubeChannel: retDataDomain.configuracoes.urlYouTubeChannel ? retDataDomain.configuracoes.urlYouTubeChannel : "https://www.youtube.com/channel/UCZj7s0EdxFN4ZAejFdknndQ",
    };
  }).catch((err) => {
    console.log(err);
    throw err;
  });
};