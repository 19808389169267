import React, { Suspense, lazy } from "react";
const FooterBar = lazy(() => import("../components/FooterBar"));
const Header = lazy(() => import("../components/Header"));
const CookiesNotification = lazy(() => import("../components/cookies/CookiesNotification"));
const HelpdeskStart = lazy(() => import("../components/helpdesk/HelpdeskStart"));
const SpeedNotification = lazy(() => import("../components/SpeedNotification"));
const BannerPortals = lazy(() => import("../components/BannerPortals"));

export default function Layout(props) {
  let currentPath = window.location.pathname;

  return (
    <div id="wrap" className="d-flex flex-column">
      <Suspense fallback={<div></div>}>
        <CookiesNotification />
      </Suspense>
      <Suspense fallback={<div></div>}>
        <HelpdeskStart />
      </Suspense>
      <div id="header" className="d-flex flex-column">
        <header>
          <Suspense fallback={<div></div>}>
            <Header subdomain={props.subdomain} />
          </Suspense>
        </header>
        {currentPath !== "/" && !currentPath.includes("/sie") && (
          <div className="d-flex w-100 col-12 sticky-top">
            <Suspense fallback={<div></div>}>
              <BannerPortals showFixed={true} />
            </Suspense>
          </div>
        )}
      </div>
      {/* A altura minima é igual a altura do viewport - altura do header - altura do footer */}
      <div
        id="main"
        className="d-flex"
        style={currentPath !== "/" && !currentPath.includes("/sie") ? { marginTop: "120px", minHeight: "calc(100vh - 56px - 251px)" } : { marginTop: "56px", minHeight: "calc(100vh - 56px - 251px)" }}
      >
        <div className="d-flex w-100 flex-column">
          <Suspense fallback={<div></div>}>
            <SpeedNotification />
          </Suspense>
          {props.children}
        </div>
      </div>
      {/* </div> */}
      {/* </div> */}
      <div id="footer" className="d-flex">
        <footer>
          <Suspense fallback={<div></div>}>
            <FooterBar subdomain={props.subdomain} />
          </Suspense>
        </footer>
      </div>
    </div>
  );
}
