import { db } from "../utils/firestore";
import {
  doc,
  getDoc,
  collection,
  addDoc,
  getDocs,
  updateDoc,
  // deleteDoc,
  query,
  orderBy,
  where,
  startAfter,
  endBefore,
  limit,
  setDoc,
  Timestamp,
  getCountFromServer,
} from "firebase/firestore";
import { uniq } from "../utils/arrayUtils";
import { generateTrigam } from "../utils/trigamUtils";
import axios from "axios";
import { PraticanteModelo } from "../components/entidades/praticantes/models/praticate.modelo";

//const dbCollection = db.collection("/usersgroups");

class usersService {
  //import { db } from '../utils/firestore'; // update with your path to firestore config
  //import { doc, getDoc } from "firebase/firestore";

  getRulesOwned = async (id) => {
    var allRulesOwned = [];
    try {
      if (id) {
        await getDoc(doc(db, "users", id)).then(async (userSnapshot) => {
          if (userSnapshot.exists()) {
            let usersgroupsowned = userSnapshot.data().usersgroupsowned;
            await getDocs(query(collection(db, "usersgroups"), where("metadados.active", "==", true))).then((usersGroups) => {
              if (usersGroups) {
                usersGroups.docs.map((doc) => {
                  if (usersgroupsowned.includes(doc.id)) {
                    allRulesOwned = [...allRulesOwned, ...doc.data().rulesowned];
                  }
                });
              }
            });
          }
        });
        return uniq(allRulesOwned);
      } else {
        throw new Error("Registro não encontrado!");
      }
    } catch (error) {
      throw error;
    }
  };
  //usage: getUsersGroupsOwned(id);

  getRulesOwnedUnderPortalID = async (id, portalID) => {
    var allRulesOwned = [];
    try {
      if (id) {
        await getDoc(doc(db, "users", id)).then(async (userSnapshot) => {
          if (userSnapshot.exists()) {
            let usersgroupsowned = [];
            userSnapshot.data().usersgroupsowned.map((thisGroupsOwned) => {
              if (thisGroupsOwned.portalID === portalID) {
                thisGroupsOwned.groupsOwned.map((groupOwned) => {
                  usersgroupsowned.push(groupOwned);
                });
              }
            });
            await getDocs(query(collection(db, "usersgroups"), where("metadataactive", "==", true))).then((usersGroups) => {
              if (usersGroups) {
                usersGroups.docs.map((doc) => {
                  if (usersgroupsowned.includes(doc.id)) {
                    allRulesOwned = [...allRulesOwned, ...doc.data().rulesowned];
                  }
                });
              }
            });
          } else {
            //console.log("User not found!");
          }
        });
        return uniq(allRulesOwned);
      } else {
        throw new Error("Registro não encontrado!");
      }
    } catch (error) {
      throw error;
    }
  };
  //usage: getUsersGroupsOwned(id);

  getOne = async (id) => {
    try {
      if (id) {
        const noteSnapshot = await getDoc(doc(db, "users", id));
        if (noteSnapshot.exists()) {
          return noteSnapshot.data();
        } else {
          throw new Error("Registro não encontrado!");
        }
      } else {
        return null;
      }
    } catch (error) {
      throw error;
    }
  };
  //usage: getNote(id);

  getOneByPinCode = async (pinCode) => {
    try {
      if (pinCode) {
        const noteSnapshot = await getDocs(query(collection(db, "users"), where("dadosSeguranca.pincode", "==", pinCode)));
        if (noteSnapshot.docs.length > 0) return { id: noteSnapshot.docs[0].id, ...noteSnapshot.docs[0].data() };
        else throw new Error("Registro não encontrado!");
      } else {
        return null;
      }
    } catch (error) {
      throw error;
    }
  };
  //usage: getNote(id);

  getAll = async (limite) => {
    if (limite === undefined) {
      const querySnapshot = await getDocs(
        query(collection(db, "users"), where("metadados.active", "==", true), orderBy("dadosPessoais.name", "asc"))
      );
      return querySnapshot.docs;
    } else {
      const querySnapshot = await getDocs(
        query(collection(db, "users"), where("metadados.active", "==", true), limit(limite), orderBy("dadosPessoais.name", "asc"))
      );
      return querySnapshot.docs;
    }
  };

  getCollectionSize = async () => {
    // const querySnapshot = await getDocs(
    //   query(
    //     collection(db, "users"),
    //     where("metadados.active", "==", true)
    //     // orderBy("dadosPessoais.name", "asc")
    //   )
    // );
    // return querySnapshot.docs.length;
    const q = query(collection(db, "users"), where("metadados.active", "==", true));
    const snapshot = await getCountFromServer(q);
    //console.log("count: ", snapshot.data().count);
    return snapshot.data().count;
  };

  getFilteredCollection = async (filterTarget, operation, filterValue) => {
    let querySnapshot = {};
    //console.log(filterTarget, operation, filterValue);
    if (filterTarget === "dadosPessoais.name")
      querySnapshot = await getDocs(
        query(collection(db, "users"), where(filterTarget, operation, filterValue), orderBy("dadosPessoais.name", "asc"))
      );
    else
      querySnapshot = await getDocs(
        query(collection(db, "users"), where(filterTarget, operation, filterValue), orderBy(filterTarget, "dadosPessoais.name", "asc"))
      );
    return querySnapshot.docs;
  };

  // getFilteredCollection = async (filterTarget, operation, filterValue) => {
  //   const querySnapshot = await getDocs(
  //     query(collection(db, "users"), where(filterTarget, operation, filterValue), orderBy("dadosPessoais.name", "asc"))
  //   );
  //   return querySnapshot.docs;
  // };

  getCollectionSearchActiveByPrefixLimited = async (queryTarget, queryText, queryLimit) => {
    //console.log(queryTarget, queryText, queryLimit);
    const querySnapshot = await getDocs(
      query(
        collection(db, "users"),
        where("metadados.active", "==", true),
        // where(queryTarget, ">=", queryText),
        // where(queryTarget, "<=", queryText + "\uf8ff"),
        where(queryTarget, "array-contains-any", [...generateTrigam(queryText)]),
        orderBy("dadosPessoais.name", "asc"),
        limit(queryLimit)
      )
    );
    return querySnapshot.docs;
  };

  getCollectionSearchByPrefix = async (queryTarget, queryText) => {
    const querySnapshot = await getDocs(
      query(
        collection(db, "users"),
        // where(queryTarget, ">=", queryText),
        // where(queryTarget, "<=", queryText + "\uf8ff"),
        where(queryTarget, "array-contains-any", [...generateTrigam(queryText)]),
        orderBy("dadosPessoais.name", "asc")
      )
    );
    return querySnapshot.docs;
  };

  getPrevPage = async (firstVisible, pageSize) => {
    let querySnapshot = await getDocs(
      query(
        collection(db, "users"),
        where("metadados.active", "==", true),
        orderBy("dadosPessoais.name", "asc"),
        limit(pageSize),
        endBefore(firstVisible)
      )
    );
    return querySnapshot.docs;
  };

  getNextPage = async (lastVisible, pageSize) => {
    let querySnapshot = null;
    if (lastVisible !== null) {
      querySnapshot = await getDocs(
        query(
          collection(db, "users"),
          where("metadados.active", "==", true),
          orderBy("dadosPessoais.name", "asc"),
          limit(pageSize),
          startAfter(lastVisible)
        )
      );
    } else {
      querySnapshot = await getDocs(
        query(collection(db, "users"), where("metadados.active", "==", true), orderBy("dadosPessoais.name", "asc"), limit(pageSize))
      );
    }
    // //console.log("querySnapshot=", querySnapshot);
    return querySnapshot.docs;
  };

  create = async (value) => {
    try {
      // value = { ...value, dadosPessoais: { ...value.dadosPessoais, trigam: generateTrigam(value.dadosPessoais.name) } };
      // if (isNaN(value.metadados.creation)) {
      //   value.metadados.creation = Timestamp.fromDate(new Date());
      // }
      // if (value.metadados.owner === null) value.metadados.owner = value.dadosPessoais.name;
      // return await addDoc(collection(db, "users"), value);
      return await this.createWithID("", value);
    } catch (error) {
      throw error;
    }
  };

  createWithID = async (UID, value, doNotCreatePraticante) => {
    try {
      // console.log("createWithID => UID = ", UID, " value = ", value);
      let userAuth = await this.createAuthenticationUser(UID, value.dadosContato.email, value.dadosPessoais.name);
      if (userAuth === null) throw new Error("Erro ao criar usuário no Firebase Authentication!");
      // console.log("userAuth = ", userAuth);
      if (UID === null || UID === "") UID = userAuth.data.uid;
      //console.log("UID = ", UID);
      value = { ...value, dadosPessoais: { ...value.dadosPessoais, trigam: generateTrigam(value.dadosPessoais.name) } };
      if (isNaN(value.metadados.creation)) {
        value.metadados.creation = Timestamp.fromDate(new Date());
      }
      if (value.metadados.owner === null) value.metadados.owner = value.dadosPessoais.name;
      //console.log("users => UID = ", UID, "value = ", value);
      // let returnValue = await setDoc(doc(db, "users", UID.toString()), value);
      return await setDoc(doc(db, "users", UID.toString()), value)
        .then(async () => {
          if (!(doNotCreatePraticante !== undefined && doNotCreatePraticante === true)) {
            await this.createPraticante(UID, value);
          }
          let returnValue = await this.getOne(UID);
          returnValue.id = UID;
          //console.log("returnValue = ", returnValue);
          return returnValue;
        }).catch((error) => {
          throw error;
        });
    } catch (error) {
      throw error;
    }
  };

  createPraticante = async (UID, value) => {
    try {
      let newPraticante = PraticanteModelo();
      newPraticante = {
        ...newPraticante,
        dadosPessoais: {
          ...newPraticante.dadosPessoais,
          name: value.dadosPessoais.name,
          trigam: value.dadosPessoais.trigam,
          searchableName: value.dadosPessoais.name.toLowerCase(),
        },
        dadosContato: {
          ...newPraticante.dadosContato,
          email: value.dadosContato.email,
        },
        metadados: {
          ...newPraticante.metadados,
          ownerid: value.metadados.ownerid,
          owner: value.metadados.owner,
        },
      };
      // //console.log("createPraticante praticantes => UID = ", UID, "newPraticante = ", newPraticante);
      await setDoc(doc(db, "praticantes", UID.toString()), newPraticante);
      return true;
    } catch (error) {
      throw error;
    }
  };

  checkIfEmailExists = async (email) => {
    try {
      let returnValue = null;
      let urlService = process.env.REACT_APP_BACKEND_API_URL + "/api/usr/chkemail";
      // //console.log("urlService:", urlService);
      // //console.log("chkemail => email = ", email);
      return await axios.post(urlService, { email: email })
        .then((response) => {
          // //console.log(response);
          returnValue = response.data.value;
          return returnValue;
        }, (error) => {
          throw error;
          // ConsoleLog.log(error);
        });
    } catch (error) {
      throw error;
    }
  };


  checkIfEmailExistsAndID = async (id, email) => {
    try {
      let returnValue = null;
      let urlService = process.env.REACT_APP_BACKEND_API_URL + "/api/usr/chkemail";
      // //console.log("urlService:", urlService);
      // //console.log("chkemail => email = ", email);
      // this.getOne(id).then((retData) => {
      //   console.log("retData = ", retData);
      //   if(retData === undefined) {
      //     console.log("Usuário não encontrado!");
      //   }

      // }).catch((error) => {
      //   throw error;
      // });
      return await axios.post(urlService, { email: email })
        .then((response) => {
          console.log(response);
          if (response.data.value === true && response.data.uid === id)
            returnValue = [1, ""]; // Email exists and belongs to the same user
          else if (response.data.value === true && response.data.uid !== id)
            returnValue = [-1, response.data.uid]; // Email exists and belongs to another user
          else
            returnValue = [0, ""]; // Email does not exist
          return returnValue;
        }, (error) => {
          throw error;
          // ConsoleLog.log(error);
        });
    } catch (error) {
      throw error;
    }
  };

  createAuthenticationUser = async (UID, email, displayname) => {
    try {
      let returnValue = null;
      let urlService = process.env.REACT_APP_BACKEND_API_URL + "/api/usr/create";
      // console.log("urlService:", urlService);
      // console.log("createAuthenticationUser => UID = ", UID, " email = ", email, " displayname = ", displayname);
      return await axios.post(urlService, { uid: UID, email: email, displayname: displayname })
        .then((response) => {
          console.log(response);
          returnValue = response;
          return returnValue;
        }, (error) => {
          console.log(error);
          throw error;
          // ConsoleLog.log(error);
        });
      // return returnValue;
    } catch (error) {
      console.log(error);
      throw error;
    }
  };

  update = async (id, value) => {
    try {
      value = { ...value, dadosPessoais: { ...value.dadosPessoais, trigam: generateTrigam(value.dadosPessoais.name) } };
      if (isNaN(value.metadados.creation)) value.metadados.creation = Timestamp.fromDate(new Date());
      if (value.metadados.owner === null) value.metadados.owner = value.dadosPessoais.name;
      return setDoc(doc(db, "users", id.toString()), value);
    } catch (error) {
      throw error;
    }
  };

  updateActiveState = async (id, newStateValue) => {
    // //console.log("updateActiveState => id = ", id, " newStateValue = ", newStateValue);
    try {
      this.getOne(id).then((retData) => {
        let ActualVersion = {
          ...retData,
          metadados: {
            ...retData.metadados,
            active: newStateValue,
          },
        };
      });
      return await updateDoc(doc(db, "users", id), {
        metadataactive: newStateValue,
      });
    } catch (error) {
      throw error;
    }
  };

  updateEmail = async (id, newEmail) => {
    try {
      let urlService = process.env.REACT_APP_BACKEND_API_URL + "/api/usr/changeemail";
      return await axios.post(urlService, { uid: id, email: newEmail })
        .then(async (response) => {
          if (response.data.value) {
            return await updateDoc(doc(db, "users", id), { "dadosContato.email": newEmail })
              .then(async () => {
                return await updateDoc(doc(db, "praticantes", id), { "dadosContato.email": newEmail })
                  .then(async () => {
                    return true;
                  }, (error) => {
                    throw error;
                  });
              }, (error) => {
                throw error;
              });
          }
          else {
            throw new Error("Erro ao alterar email no Firebase Authentication!");
          }
        }, (error) => {
          throw error;
        });
    } catch (error) {
      throw error;
    }

    // //console.log("updateLogin => id = ", id, " newEmail = ", newEmail);
    // try {
    //   return await updateDoc(doc(db, "users", id), { "dadosContato.email": newEmail })
    //   .then(async () => {
    //     return await updateDoc(doc(db, "praticantes", id), { "dadosContato.email": newEmail })
    //     .then(async () => {
    //       let urlService = process.env.REACT_APP_BACKEND_API_URL + "/api/usr/changeemail";
    //       return await axios.post(urlService, {uid: id, email: newEmail})
    //       .then((response) => {
    //         return response.data.value;
    //         // return true;
    //       }, (error) => {
    //         throw error;
    //       });      
    //     });
    //   });
    // } catch (error) {
    //   throw error;
    // }
  };

  updateName = async (id, newName) => {
    // //console.log("updateLogin => id = ", id, " newName = ", newName);
    try {
      let urlService = process.env.REACT_APP_BACKEND_API_URL + "/api/usr/changedisplayname";
      return await axios.post(urlService, { uid: id, displayname: newName })
        .then(async (response) => {
          if (response.data.value) {
            return await updateDoc(doc(db, "users", id), {
              "dadosPessoais.name": newName,
              "dadosPessoais.searchableName": newName.toLowerCase(),
              "dadosPessoais.trigam": generateTrigam(newName),
            }).then(async () => {
              return await updateDoc(doc(db, "praticantes", id), {
                "dadosPessoais.name": newName,
                "dadosPessoais.searchableName": newName.toLowerCase(),
                "dadosPessoais.trigam": generateTrigam(newName),
              }).then(async () => {
                return true;
              }, (error) => {
                throw error;
              });
            }, (error) => {
              throw error;
            });
          }
        }, (error) => {
          throw error;
        });
    } catch (error) {
      throw error;
    }
  };

  addRulesOwnedUnderPortalID = async (id, portalID, newRule) => {
    try {
      await this.getOne(id).then(async (retData) => {
        retData.usersgroupsowned.map((thisGroupsOwned) => {
          if (thisGroupsOwned.portalID === portalID) {
            newRule.map((rule) => {
              if (!thisGroupsOwned.groupsOwned.includes(rule)) thisGroupsOwned.groupsOwned.push(rule);
            });
          }
        });
        await updateDoc(doc(db, "users", id), retData);
      });
    } catch (error) {
      throw error;
    }
  };

  removeRulesOwnedUnderPortalID = async (id, portalID, rulesToRemove) => {
    try {
      return await this.getOne(id).then(async (retData) => {
        retData.usersgroupsowned.map((thisGroupsOwned) => {
          // //console.log("thisGroupsOwned=", thisGroupsOwned);
          if (thisGroupsOwned.portalID === portalID) {
            thisGroupsOwned.groupsOwned = thisGroupsOwned.groupsOwned.filter((group) => !rulesToRemove.includes(group));
          }
        });
        return await updateDoc(doc(db, "users", id), {
          usersgroupsowned: retData.usersgroupsowned,
        });
      });
    } catch (error) {
      throw error;
    }
  };

  delete = async (id) => {
    try {
      this.getOne(id).then((retData) => {
        let ActualVersion = {
          ...retData,
          metadados: {
            ...retData.metadados,
            active: false,
          },
        };
        updateDoc(doc(db, "praticantes", id), ActualVersion).then((retValue) => {
          return retValue;
        });
      });
      return await updateDoc(doc(db, "users", id), {
        metadataactive: false,
      });
    } catch (error) {
      throw error;
    }
  };


  redefinePassword = async (UID, email, password) => {
    try {
      let returnValue = null;
      let urlService = process.env.REACT_APP_BACKEND_API_URL + "/api/usr/rstuser";
      // console.log("urlService:", urlService);
      // console.log("createAuthenticationUser => UID = ", UID, " email = ", email, " displayname = ", displayname);
      return await axios.post(urlService, { uid: UID, email: email, password: password })
        .then((response) => {
          console.log(response);
          returnValue = response;
          return returnValue;
        }, (error) => {
          console.log(error);
          throw error;
          // ConsoleLog.log(error);
        });
      // return returnValue;
    } catch (error) {
      console.log(error);
      throw error;
    }
  };


}

export default new usersService();
